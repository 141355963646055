export type ProtectedRouteProps = {
  authenticationPath: string;
  outlet: JSX.Element;
};

export default function ProtectedRoute({
  authenticationPath,
  outlet,
}: ProtectedRouteProps) {
  if (localStorage.getItem("access_token") != null) {
    return outlet;
  } else {
    localStorage.clear();
    window.location.href ="/login";
    return outlet;
  }
}
