import 'bootstrap/dist/css/bootstrap.css'
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import store from './Redux/store';
import Navigation from './navigation';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
const App = () => {
  return (
    <>
     <Provider store={store}>
      <div className="App">
        <Navigation />
        <ToastContainer />
      </div>
      </Provider>
    </>
  );
};
export default App;
