import { Routes, Route, RoutesProps } from "react-router-dom";

import React, { Suspense } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Main from "../components/Main";

import DefaultPage from "../components/Common/DefaultPage";
import MySql from "../components/MySql/MySql";

const Navigation = () => {
  interface ProtectedRouteProps extends RoutesProps {
    authenticationPath: string;
  }
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    authenticationPath: "/login",
  };

  const Login = React.lazy(() => import("../components/Login/Login"));
  const ForgotPassword = React.lazy(
    () => import("../components/Login/ForgotPassword")
  );
  const SuccessSent = React.lazy(() => import("../components/Login/Success"));
  const AddClient = React.lazy(
    () => import("../components/ManageClients/AddClient")
  );

  const Dashboard = React.lazy(
    () => import("../components/Dashboard/Dashboard")
  );
  const Integration = React.lazy(
    () => import("../components/Integration/Integration")
  );
  const ManageClients = React.lazy(
    () => import("../components/ManageClients/ManageClients")
  );
  const Profile = React.lazy(
    () => import("../components/Profile/Profile")
  );

  const ViewClient = React.lazy(
    () => import("../components/ManageClients/ViewClient")
  );
  const ComplianceUser = React.lazy(
    () => import("../components/ComplianceUser/ComplianceUser")
  );

  const ExportData = React.lazy(
    () => import("../components/ManageClients/ExportData")
  );


  const PrivacyPolicy = React.lazy(
    () => import("../components/CMS/PrivacyPolicy")
  );
  const Subscription = React.lazy(
    () => import("../components/Subscription/Subscription")
  );
  const AddSubscription = React.lazy(
    () => import("../components/Subscription/AddSubscription")
  );
  const ResetPassword = React.lazy(
    () => import("../components/Login/SetPassword")
  );

  const ConnectorsReport = React.lazy(
    () => import("../components/ConnectorsReport/ConnectorsReport")
  );
  const Imessage = React.lazy(
    () => import("../components/Imessage/Imessage")
  );
  const UserMachines = React.lazy(
    () => import("../components/Imessage/UserMachines")
  );

  const MySql = React.lazy(
    () => import("../components/MySql/MySql")
  );
  const AiKeywords = React.lazy(
    () => import("../components/AiKeywords/AiKeywords")
  );
  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route
            path="/login"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <Login />{" "}
              </Suspense>
            }
          />
          <Route
            path="/reset-password/:token"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ResetPassword />{" "}
              </Suspense>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ForgotPassword />{" "}
              </Suspense>
            }
          />
          <Route
            path="/success"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <SuccessSent />{" "}
              </Suspense>
            }
          />
          <Route
            path="/keywords"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <AiKeywords />{" "}
              </Suspense>
            }
          />


          <Route
            path="/"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Main />}
              />
            }
          >
            <Route
              path="/integration"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Integration />{" "}
                </Suspense>
              }
            />
            <Route
              path="/dashboard"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Dashboard />{" "}
                </Suspense>
              }
            />
            <Route
              path="/manage-clients"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ManageClients />{" "}
                </Suspense>
              }
            />
            <Route
              path="/manage-clients/view-client/:id"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ViewClient />{" "}
                </Suspense>
              }
            />
            <Route
              path="/manage-clients/add-client"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddClient />{" "}
                </Suspense>
              }
            />
            <Route
              path="/manage-clients/add-client/:id"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddClient />{" "}
                </Suspense>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <PrivacyPolicy />{" "}
                </Suspense>
              }
            />
            <Route
              path="/subscription"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Subscription />{" "}
                </Suspense>
              }
            />
            <Route
              path="subscription/add-subscription"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddSubscription />{" "}
                </Suspense>
              }
            />
            <Route
              path="subscription/add-subscription/:id"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddSubscription />{" "}
                </Suspense>
              }
            />
            <Route
              path="/compliance-user"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ComplianceUser />{" "}
                </Suspense>
              }
            />

            <Route
              path="/export/:id"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ExportData />{" "}
                </Suspense>
              }
            />



            <Route
              path="/profile"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Profile />{" "}
                </Suspense>
              }
            />

            <Route
              path="/connectors-report"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ConnectorsReport />{" "}
                </Suspense>
              }
            />
            <Route
              path="/imessage"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Imessage />{" "}
                </Suspense>
              }
            />

            <Route
              path="/user-machine"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <UserMachines />{" "}
                </Suspense>
              }
            />
            <Route
               path="/connection-dashboard"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <MySql />{" "}
                </Suspense>
              }
            />


          </Route>
          {/* <Route
            index
            element={<Suspense fallback={<></>}> <DefaultPage /> </Suspense>}
          /> */}
        </Routes>
      </div>
    </>
  );
};

export default Navigation;
